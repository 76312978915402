import { TermsAndConditionsContent } from "../components/TermsAndConditions";
import { Layout } from "../layout";
import React from "react";
function TermsAndConditions({ location }: any) {
	return (
		<Layout location={location} title="Kredi | Términos y Condiciones" >
			<TermsAndConditionsContent />
		</Layout>
	)
}

export default TermsAndConditions