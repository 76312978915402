import { Grid, Typography } from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import { useStyles } from "./TermsAndConditions.styles";

export function TermsAndConditionsContent() {
	const classes = useStyles()
	return (
		<Grid container className={classes.root}>
			<Grid item>
				<Typography color="primary" className={classes.title}>Términos y Condiciones de Uso de la Plataforma</Typography>

			</Grid>
			<Grid item>
				<Typography className={classes.text}>
					A los que se sujeta el uso del presente sitio de internet, bajo el dominio www.kredi.mx La Plataforma,
					operada por <strong> FINANCIERA INTELIGENTE, SOCIEDAD ANÓNIMA DE CAPITAL VARIABLE, SOCIEDAD FINANCIERA DE OBJETO MÚLTIPLE,
						ENTIDAD NO REGULADA </strong> (La SOFOM) así como de cualesquiera subdominios, interfaces y páginas referenciadas a ella.
					Se recomienda enfáticamente a toda persona que acceda al Sitio ("Visitante") leer <strong>cuidadosamente y entienda a cabalidad los presentes Términos y Condiciones,</strong>
					especialmente a las personas que decidan darse de alta como "Usuarios", a quienes además <strong>se les recomienda obtener asesoría de su confianza </strong> respecto de los aspectos
					legales, financieros, fiscales y/o de cualquier otra índole que pudiera implicar su alta, así como la utilización de la La Plataforma.
					La connotación que se atribuye a las expresiones anteriormente entrecomilladas puede ser consultada en el glosario de definiciones en la parte final de este documento.
				</Typography>
			</Grid>
			<Grid item>
				<Typography className={classes.subtitle}> Implicaciones de alta. </Typography>
			</Grid>
			<Grid item>
				<Typography className={classes.text}>El alta como Usuario en el Sitio implica la manifestación expresa de la voluntad del propio Usuario para producir las consecuencias jurídicas que se describen en este instrumento respecto de cada tipo de alta. </Typography>
			</Grid>
			<Grid item>
				<Typography className={classes.text}>La creación de la cuenta por parte del Usuario, así como su ingreso posterior a la Plataforma, presupone el consentimiento para la solicitud de crédito. Estas implicaciones legales y otras que se describen más adelante en este documento respecto de cada tipo de alta, son de especial relevancia para los Usuarios, por lo que antes de darse de alta, se recomienda la lectura completa a detalle y asesorada del presente documento, así como de los demás documentos relacionados a los que podrá quedar vinculado un Usuario.</Typography>
			</Grid>
			<Grid item>
				<Typography className={classes.subtitle}>Descripción general del Sitio.</Typography>
			</Grid>
			<Grid item>
				<Typography className={classes.text}> El Sitio ha sido concebido como un espacio dirigido a personas que requieran recursos o financiamientos.</Typography>
				<Typography className={classes.text}> Las personas interesadas en obtener financiamientos deben darse de alta como "Usuarios".</Typography>
				<Typography className={classes.text}>Cualquier persona puede ingresar como "Visitante" al Sitio, con acceso a la información general del mismo, incluyendo al presente documento de Términos y Condiciones de Uso de la Plataforma, con la finalidad de que pueda valorar y, en su caso, decidir respecto de su alta como Usuario. Únicamente los Visitantes que se den de alta como Usuarios tendrán acceso a la la Plataforma, y en consecuencia, hayan aceptado de forma expresa a través de la Plataforma, los presentes Términos y Condiciones de Uso de la Plataforma.</Typography>
				<Typography className={classes.text}>El alta como Usuario implica la autorización para que “La SOFOM” en su nombre y representación lleve a cabo los actos y gestiones para que un tercero interesado le otorgue un crédito.</Typography>
			</Grid>
			<Grid item>
				<Typography className={classes.subtitle}>Implicaciones para solicitantes de crédito.</Typography>
			</Grid>
			<Grid item>
				<Typography className={classes.text}>El hecho de que el Usuario se dé de alta en la Plataforma, implicará la manifestación expresa de voluntad de dicho Usuario para que “La SOFOM” lo contacte con el objeto de ofertarle un crédito.</Typography>
				<Typography className={classes.text}>Los Usuarios deberán proporcionar:</Typography>
				<ol className={classes.listOl}>
					<li><Typography>Información relativa al monto solicitado;</Typography></li>
					<li><Typography>Elegir el plazo para su pago de entre las opciones que se tengan habilitadas en la la Plataforma, e</Typography></li>
					<li><Typography>Indicar el destino que le pretendan dar al crédito o financiamiento. También se les solicitará información respecto de su identidad, capacidad de pago y fuentes de ingresos, así como copia de la documentación que, a satisfacción de “La SOFOM”, acredite dicha información.</Typography></li>
				</ol>
				<Typography className={classes.text}>Al formularse la solicitud, los Usuarios autorizan a “La SOFOM” para que ésta realice un Análisis de Crédito con base a la información proporcionada por los propios Usuarios, respecto de su capacidad de pago y la viabilidad que les otorgue un crédito.</Typography>
				<Typography className={classes.text}>El Análisis de Crédito comprenderá por lo menos dos etapas:</Typography>
				<ol className={classes.listOl}>
					<li><Typography>Una previa o inicial, en la que “La SOFOM” determina si una Solicitud de Crédito es viable o no, otorgándole, en su caso, un "Grado de Calificación; y</Typography></li>
					<li><Typography>Una segunda etapa, en la que se valida, en la medida de lo posible, la veracidad de la información proporcionada por el Acreditado respecto de su identidad, fuente de ingresos, capacidad y voluntad de pago.</Typography></li>
				</ol>
				<Typography className={classes.text}>Si la Solicitud de Crédito es autorizada, “La SOFOM” continua con el proceso de crédito. </Typography>
				<Typography className={classes.text}>Los Usuarios reconocen que <strong>el hecho de formular una Solicitud de Crédito, NO implica garantía alguna de que </strong>“La SOFOM” <strong> le otorgue el crédito o financiamiento en cuestión. </strong> </Typography>
			</Grid>
			<Grid item>
				<Typography className={classes.subtitle}>Confidencialidad.</Typography>
			</Grid>
			<Grid item>
				<Typography className={classes.text}>
					El Usuario reconoce que toda la información contenida en el Sitio, así como aquella que“La SOFOM” podrá entregarle, ya sea en forma escrita, electrónica o verbal, es información cuyo contenido el Usuario acuerda proteger con el carácter de confidencial (la "Información Confidencial"). La Información Confidencial comprenderá, en forma enunciativa más no limitativa, correspondencia, información técnica, información de terceros y/o otros usuarios del Sitio, información comercial relativa a la organización y actividades del Sitio, conocimientos técnicos y contractuales de “La SOFOM”. Asimismo, el Usuario acuerda y reconoce que dentro de la Información Confidencial que le será entregada por “La SOFOM”, podrán existir secretos industriales, entendiendo por éstos toda aquella información propiedad de “La SOFOM” de aplicación industrial y comercial que le permite obtener y mantener, entre otras cosas, ventajas económicas y de mercado frente a sus competidores. El Usuario está de acuerdo y acepta conservar la Información Confidencial en estricta confidencialidad, y en este acto se obliga a no vender, divulgar, transferir, modificar, traducir, reproducir ni poner de otra forma Información Confidencial a disposición de terceros. El Usuario se obliga a poner el cuidado necesario en la protección de la Información Confidencial. Por otra parte, el Usuario acuerda y reconoce que las obligaciones de confidencialidad estarán vigentes durante todo el tiempo en que la Información Confidencial conserve dicho carácter. Asimismo, el Usuario reconoce que la divulgación no autorizada de la Información Confidencial es castigada por la Ley de la Propiedad Industrial e incluso puede constituir la comisión de un delito.
				</Typography>
			</Grid>
			<Grid item>
				<Typography className={classes.subtitle}>Propiedad intelectual.</Typography>
			</Grid>
			<Grid item>
				<Typography className={classes.text}>
					El Sitio, los logotipos y todo el material que aparece en el Sitio, son marcas, nombres de dominio, nombres comerciales y obras artísticas propiedad de “La SOFOM” y/o sus respectivos titulares y están protegidos por los tratados internacionales y las leyes aplicables en materia de propiedad intelectual y derechos de autor. Los derechos de autor sobre el contenido, organización, recopilación, compilación, información, logotipos, fotografías, imágenes, programas, aplicaciones, y en general, cualquier información contenida o publicada en el Sitio se encuentran debidamente protegidos a favor de “La SOFOM”, sus afiliados, proveedores y/o de sus respectivos propietarios, de conformidad con la legislación aplicable en materia de propiedad intelectual e industrial.
				</Typography>
				<Typography className={classes.text}>
					El Usuario sólo podrá imprimir y/o copiar cualquier información contenida o publicada en el Sitio exclusivamente para uso personal, quedando terminantemente prohibido el uso comercial de dicha información. La reimpresión, publicación, distribución, asignación, sublicencia, venta, reproducción electrónica o por otro medio, parcial o total, de cualquier información, documento o gráfico que aparezca en el Sitio, para cualquier uso distinto al personal no comercial le está expresamente prohibido al Usuario.
				</Typography>
				<Typography className={classes.text}>
					Se prohíbe expresamente al Usuario modificar, alterar o suprimir, ya sea en forma total o parcial, los avisos, marcas, nombres comerciales, señas, anuncios, logotipos o en general cualquier indicación que se refiera a la propiedad de la información contenida en el Sitio. Por otra parte, el Usuario asume en este acto la obligación de notificar a “La SOFOM”, sobre cualquier uso o violación de parte de terceros de las marcas o cualesquiera otros derechos de propiedad industrial o intelectual propiedad de “La SOFOM”, tan pronto como el Usuario tenga conocimiento de dichas violaciones o usos indebidos. Lo anterior, en el claro entendido que el Usuario únicamente deberá notificar cualquier violación a los derechos de propiedad industrial o intelectual de “La SOFOM”, sin que le esté permitido defender o en cualquier forma actuar en nombre o representación de “La SOFOM” en la defensa de sus derechos de propiedad industrial o intelectual.
				</Typography>
				<Typography className={classes.text}>
					En caso de que el Usuario transmita a “La SOFOM” cualquier información, programas, aplicaciones, software o en general cualquier material que requiera ser licenciado a través del Sitio, el Usuario otorga a “La SOFOM” una licencia perpetua, universal, gratuita, no exclusiva, mundial y libre de regalías, que incluye los derechos de sublicenciar, vender, reproducir, distribuir, transmitir, crear trabajos derivados, exhibirlos y ejecutarlos públicamente. Lo establecido en el presente párrafo se aplicará igualmente a cualquier otra información que el Usuario envíe o transmita a “La SOFOM” por cualquier medio.
				</Typography>
				<Typography className={classes.text}>
					En caso de que algún Usuario o tercero consideren que cualquiera de los contenidos que se encuentren o sean introducidos en dicho Sitio y/o cualquiera de sus servicios, violen sus derechos de propiedad intelectual deberán enviar una notificación por escrito a “La SOFOM”.
				</Typography>
			</Grid>
			<Grid item>
				<Typography className={classes.subtitle}>Declaraciones adicionales del usuario</Typography>
			</Grid>
			<Grid item>
				<Typography className={classes.text}>
					El alta de un Usuario, implican las siguientes declaraciones y manifestaciones de voluntad del Usuario, en adición a cualesquiera otras que se desprendan conforme a lo indicado en los presentes Términos y Condiciones:
				</Typography>
				<ol className={classes.listOl}>
					<li><Typography>En caso de actuar en nombre propio, como Usuario persona física, el Usuario declara ser mayor de edad y tener plena capacidad para darse de alta como Usuario y para otorgar cualquier otro acto a través del Sitio;</Typography></li>
					<li><Typography>La información capturada en el Sitio, relativa a la identidad, domicilio, datos de contacto, datos de cuentas bancarias y otros datos del Usuario, es veraz, completa y correcta;</Typography></li>
					<li><Typography>Los documentos con los que el Usuario acredite la información que proporcione a través del Sitio, son y serán igualmente veraces, completos y correctos y que las copias de estos que se compartan en el Sitio son y serán reproducciones fieles y exactas de sus originales;</Typography></li>
					<li><Typography>El Usuario autoriza a “La SOFOM” para integrar un expediente de identidad, con la información y documentación que se requiera;</Typography></li>
					<li><Typography>El Usuario manifiesta que está consciente y entiende que “La SOFOM”, no asume ningún tipo de responsabilidad ante el Usuario, salvo por daños efectivos causados por dolo, siempre que se acredite debidamente que tales daños son consecuencia directa e inmediata del dolo con el que se hubiere manejado “La SOFOM”. La responsabilidad de “La SOFOM” estará limitada, en cualquier caso;</Typography></li>
					<li><Typography>En caso de que alguna acción u omisión del Usuario genere algún daño, molestia o perjuicio a “La SOFOM”, el Usuario responsable se obliga a indemnizarla y a dejarla en paz y a salvo, sin costo para ella, incluyendo el reembolso de gastos legales y honorarios de abogados, si fuera necesaria su contratación para defender los intereses de “La SOFOM” o de otros Usuarios que “La SOFOM” represente, con motivo de cualquier incumplimiento o contravención a las disposiciones legales o convencionales a las que el Usuario se encuentre sometido, lo anterior si así es determinado por autoridad judicial;</Typography></li>
					<li>
						<Typography>El Usuario está enterado de que “La SOFOM” se reserva el derecho de bloquear el acceso o remover en forma parcial o total toda información, comunicación o material que a su exclusivo juicio pueda resultar:</Typography>
						<ol className={classes.sublistOl}>
							<li><Typography>Abusivo, difamatorio u obsceno;</Typography></li>
							<li><Typography>Fraudulento, artificioso o engañoso;</Typography></li>
							<li><Typography>Violatorio de derechos de autor, marcas, confidencialidad, secretos industriales o cualquier derecho de propiedad intelectual de un tercero;</Typography></li>
							<li><Typography>Ofensivo;</Typography></li>
							<li><Typography>Que de cualquier forma contravenga lo establecido en los presentes Términos y Condiciones de uso de la Plataforma;</Typography></li>

						</ol>
					</li>
					<li>
						<Typography>
							El Usuario está consciente de que él es el único responsable por el uso de su cuenta de correo electrónico y por el manejo de los datos de nombre de usuario para el acceso a la Plataforma por el Usuario. “La SOFOM” no asume responsabilidad por el uso no autorizado por parte de terceros que utilicen la cuenta de correo electrónico del Usuario, o sus datos para el ingreso a la Plataforma;
						</Typography>
					</li>
					<li>
						<Typography>
							Cada Usuario es responsable de adquirir y dar mantenimiento al equipo informático y software que se requiera para la navegación en el Sitio y para el acceso a la Plataforma. “La SOFOM” no está obligada a suministrar ningún tipo de equipo ni software, así como tampoco el mantenimiento o soporte técnico correspondiente, por lo no asume ningún tipo de responsabilidad al respecto;
						</Typography>
					</li>
					<li>
						<Typography>
							El uso de la Plataforma es responsabilidad y corre por cuenta y riesgo de cada Usuario, no siendo imputable a “La SOFOM” ningún tipo de responsabilidad al respecto, incluyendo sin limitación, por daños, perjuicios y gastos directos o indirectos que surjan en relación con el uso de La Plataforma, su contenido o por la imposibilidad de uso, así como en caso de falla en el rendimiento de la Plataforma y/o en cualquier otro implemento que se requiera para la navegación en el Sitio;
						</Typography>
					</li>
					<li>
						<Typography>
							El Sitio y la Plataforma podrán dejar de estar disponibles temporalmente por mantenimiento u otras razones. El Usuario libera a “La SOFOM” de toda responsabilidad por cualquier error, omisión, interrupción, supresión, defecto, demora en la operación o transmisión, falla en la línea de comunicación, robo, destrucción de datos o acceso no autorizado a ellos, ni por alteración de las comunicaciones de los Usuarios;
						</Typography>
					</li>
					<li>
						<Typography>
							El Usuario libera a “La SOFOM” de toda responsabilidad por cualquier problema técnico o mal funcionamiento de cualquier red o líneas telefónicas del Usuario, sistemas informáticos en red o fuera de red, o servidores, así como tampoco por cuestiones técnicas de equipos de los Usuarios ocasionadas por problemas de tráfico de internet y/o en la Plataforma o por virus informáticos, incluyendo sin limitar, descomposturas en la computadora o dispositivos mediante los que el Usuario acceda a la Plataforma;
						</Typography>
					</li>
					<li>
						<Typography>
							El Usuario libera a “La SOFOM” de toda pérdida o daño de la información del propio Usuario, ocasionada por el uso de la Plataforma o por el acceso a cualquier contenido de terceros publicado en el Sitio o al que se direccione a través del Sitio;
						</Typography>
					</li>
					<li>
						<Typography>
							El Usuario está consciente de que el Sitio y todo su contenido pueden contener enlaces a sitios externos de terceros en los que “La SOFOM” no ejerce control alguno. Las consecuencias derivadas del acceso a tales sitios externos son responsabilidad exclusiva del propio Usuario (incluyendo infecciones en los equipos del Usuario por virus informáticos o que su contenido resulte inapropiado). Asimismo, el Usuario libera a “La SOFOM” de cualquier responsabilidad por: (i) la falta de exactitud, de veracidad, de utilidad o de adecuación para propósitos determinados; y (ii) el contenido de los sitios externos a los que se pueda acceder a través del Sitio. Cualquier reclamo que el Usuario llegare a tener con motivo del acceso o navegación por cualquier sitio externo, deberá ser dirigido directamente al administrador del sitio externo de que se trate;
						</Typography>
					</li>
					<li>
						<Typography>
							En el evento que, “La SOFOM” detecte actividad inusual o de carácter fraudulento en la cuenta de Usuario, le enviará de manera gratuita notificaciones vía telefónica, mensajes de texto SMS o correo electrónico a efecto de evitar posibles riesgos tecnológicos, cibernéticos y de fraude en los productos y servicios financieros celebrados;
						</Typography>
					</li>
					<li>
						<Typography>
							En caso de que existan o se presenten fallas en el Sitio, “La SOFOM” deberá de hacerlo del conocimiento del Usuario a través del correo electrónico y/o el número telefónico proporcionado por el mismo en la propia “Plataforma”, indicando los medios para continuar con el servicio proporcionado por “La SOFOM”;
						</Typography>
					</li>
					<li>
						<Typography>
							Las operaciones a través de la Plataforma estarán disponibles 24 horas y el Usuario podrá contactar de Lunes a Viernes de 09:00 a 18:00 al personal de “La SOFOM”, para comentarios, dudas o sugerencias.
						</Typography>
					</li>
				</ol>
			</Grid>
			<Grid item>
				<Typography className={classes.subtitle}>Claves de Acceso, Robo, Extravío u Olvido.</Typography>
			</Grid>
			<Grid item>
				<Typography className={classes.text}>Los Usuarios crean, con su correo electrónico, una cuenta de usuario personal, exclusiva e intransferible para realizar los procesos en la Plataforma.</Typography>
				<Typography className={classes.text}>El uso de la Cuenta de Usuario es personal e intransferible, por lo cual los Usuarios no se encuentran facultados para ceder los datos de validación para el acceso a su perfil a terceras personas. En caso de olvido de los datos de validación o de usurpación de estos, es obligación del Usuario informar a “La SOFOM” a través de los medios que este último ponga a su disposición ante dicha situación para que se puedan recuperar los datos de validación correspondientes, en los términos de los presentes Términos y Condiciones de uso de la Plataforma.</Typography>
				<Typography className={classes.text}>Es la entera responsabilidad del Usuario mantener de forma confidencial y en un lugar seguro sus datos para acceder a su perfil en cualquiera de las Plataformas de “La SOFOM”, por lo que, mediante el presente instrumento, el Usuario deslinda a “La SOFOM” de toda responsabilidad por la pérdida o daño alguno, sin importar su alcance o naturaleza, que resulte del acceso no autorizado al perfil del Usuario.</Typography>
			</Grid>
			<Grid item>
				<Typography className={classes.subtitle}>Mecanismos y procedimientos para el bloqueo y restablecimiento de cuentas.</Typography>
			</Grid>
			<Grid item>
				<Typography className={classes.text}>La cuenta que tengan dada de alta los Usuarios, en la Plataforma, podrá ser bloqueada temporalmente por “La SOFOM” cuando esta lo considere apropiado. </Typography>
			</Grid>
			<Grid item>
				<Typography className={classes.subtitle}>Aviso de privacidad.</Typography>
			</Grid>
			<Grid item>
				<Typography className={classes.text}>Con la finalidad de dar cumplimiento a lo previsto en la Ley Federal de Protección de Datos Personales en Posesión de Particulares, “La SOFOM” informa a los Visitantes y Usuarios que podrán consultar y aceptar el aviso de privacidad de “La SOFOM”, que se encuentra disponible en el siguiente enlace: <a target={"_blank"} href="https://kredi.mx/aviso-de-privacidad "> https://kredi.mx/aviso-de-privacidad </a></Typography>
			</Grid>
			<Grid item>
				<Typography className={classes.subtitle}>Glosario de expresiones definidas.</Typography>
			</Grid>
			<Grid item>
				<Typography className={classes.text}>Las expresiones escritas con Entrecomillado, MAYÚSCULAS o con Mayúscula Inicial que se relacionan a continuación, tendrán el significado que se indica para cada una de ellas, aplicable en singular o plural, según sea el caso, e independientemente de que al utilizarse en otra parte de los presentes Términos y Condiciones de Uso de la Plataforma se encuentren entrecomilladas, en mayúscula o no:</Typography>
			</Grid>
			<Grid item>
				<Typography className={classes.subtitle}>Análisis de Crédito.</Typography>
			</Grid>
			<Grid item>
				<Typography className={clsx(classes.text, classes.padding)}>Significa la revisión que realice “La SOFOM” de la información proporcionada por los Usuarios en relación con sus ingresos, egresos, historial crediticio y capacidad de pago, así como de la información obtenida del Buró de Crédito, y el resultado de dicha revisión, que servirá para determinar sí una Solicitud de Crédito es autorizada o no y, en su caso, para conferirle un Grado de Calificación.</Typography>
			</Grid>
			<Grid item>
				<Typography className={classes.subtitle}>Contrato de Crédito.</Typography>
			</Grid>
			<Grid item>
				<Typography className={clsx(classes.text, classes.padding)}>
					Significa cada uno de los contratos de crédito, en moneda nacional, que se celebren entre el Acreditado y el Acreditante en relación con las Solicitudes de Crédito.
				</Typography>
			</Grid>
			<Grid item>
				<Typography className={classes.subtitle}>Día Hábil.</Typography>
			</Grid>
			<Grid item>
				<Typography className={clsx(classes.text, classes.padding)}>
					Significa cualquier día del año, con excepción de sábados, domingos, los días de descanso obligatorio conforme a la Ley Federal del Trabajo, así como los días en los que por disposición de la Comisión Nacional Bancaria y de Valores, las entidades financieras sujetas a su supervisión deban suspender operaciones.
				</Typography>
			</Grid>
			<Grid item>
				<Typography className={classes.subtitle}>Información Confidencial.</Typography>
			</Grid>
			<Grid item>
				<Typography className={clsx(classes.text, classes.padding)}>
					Significa cualquier información a la que tenga acceso un Usuario con motivo de la utilización del Sitio, o que por cualquier otra forma le sea divulgada por “La SOFOM” o por otros Usuarios.
				</Typography>
			</Grid>
			<Grid item>
				<Typography className={classes.subtitle}>Plataforma.</Typography>
			</Grid>
			<Grid item>
				<Typography className={clsx(classes.text, classes.padding)}>
					Es la interfaz asociada al Sitio a través de la cual pueden darse de alta los Usuarios y sirviendo dicha plataforma, además, como un medio válido para el envío, recepción y notificación de instrucciones y otros mensajes de datos por parte de los Solicitante y “La SOFOM”.
				</Typography>
			</Grid>
			<Grid item>
				<Typography className={classes.subtitle}>Sitio.</Typography>
			</Grid>
			<Grid item>
				<Typography className={clsx(classes.text, classes.padding)}>
					Significa la página de internet "https://www.kredi.mx" (incluyendo, sin limitar, los subdominios, interfaces y páginas referenciadas a ella), propiedad de “La SOFOM”, en la que se aloja la Plataforma.
				</Typography>
			</Grid>
			<Grid item>
				<Typography className={classes.subtitle}>Solicitud de Crédito.</Typography>
			</Grid>
			<Grid item>
				<Typography className={clsx(classes.text, classes.padding)}>
					Significa cada requerimiento de crédito que envíe un Acreditado, a través de la Plataforma, a, con el objeto de ser revisado, evaluado, seleccionado y calificado por “La SOFOM”, de conformidad con sus políticas internas y otorgarle un Grado de Calificación.
				</Typography>
			</Grid>
			<Grid item>
				<Typography className={classes.subtitle}>Términos y Condiciones de Uso de la Plataforma.</Typography>
			</Grid>
			<Grid item>
				<Typography className={clsx(classes.text, classes.padding)}>
					Significan las presentes disposiciones establecidas por “La SOFOM” a las que se sujeta la utilización del Sitio, que pueden ser visualizadas por Visitantes y Usuarios antes de darse de alta en el mismo, incluyendo el correspondiente Aviso de Privacidad en relación con el tratamiento de la información que se reciba por “La SOFOM”. El alta de un Usuario y la utilización de la Firma, implica la aceptación incondicional y sin reservas de los Términos y Condiciones vigentes en el momento de alta o de uso de la Firma.
				</Typography>
			</Grid>
			<Grid item>
				<Typography className={classes.subtitle}>Usuario.</Typography>
			</Grid>
			<Grid item>
				<Typography className={clsx(classes.text, classes.padding)}>
					Significa cualquier persona física o moral que se dé de alta como Solicitante en el Sitio.

				</Typography>
			</Grid>
			<Grid item>
				<Typography className={classes.subtitle}>Acreditado.</Typography>
			</Grid>
			<Grid item>
				<Typography className={clsx(classes.text, classes.padding)}>
					Significa un Usuario que se ha dado de alta como acreditado en el Sitio, con la intención de solicitar y recibir financiamientos o crédito(s).
				</Typography>
			</Grid>
			<Grid item>
				<Typography className={classes.subtitle}>Visitante.</Typography>
			</Grid>
			<Grid item>
				<Typography className={clsx(classes.text, classes.padding)}>
					Significa cualquier persona que ingrese al Sitio.
				</Typography>
			</Grid>
			<Grid item>
				<Typography className={classes.subtitle}>La SOFOM.</Typography>
			</Grid>
			<Grid item>
				<Typography className={clsx(classes.text, classes.padding)}>
					Significa la sociedad Financiera Inteligente, S.A. de C.V., SOFOM ENR, con domicilio en Av. Vasconcelos #150 Local 10 Interior A , Col. Del Valle en San Pedro Garza García, Nuevo León.
				</Typography>
			</Grid>

			<Grid>
				<Typography className={classes.text}>Última modificación de los presentes Términos y Condiciones de Uso de la Plataforma:  Enero 25 de 2022. </Typography>
			</Grid>
		</Grid>
	)
}